(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name sliceApp.controller:AppCtrl
   *
   * @description
   *
   */
  angular
    .module('sliceApp')
    .controller('AppCtrl', AppCtrl);

  function AppCtrl($scope, $rootScope, $timeout, $mdDialog, $state, $location, App, MyOrder) {

    $scope.franchiseGraphics = {};
    $rootScope.pageTitle = "";
    $scope.currentLocation = "";
    $scope.mainViewClasses = "";
    $scope.menu = undefined;
    $scope.franchise = undefined;
    $scope.branches = [];
    $scope.rout = $state.current;
    $scope.itemsInOrderCount = 0;
    $scope.pizzaToppings = [];

    $scope.getFranchiseGraphics = function() {
      showLoader();
      App.getFranchiseGraphics().then(function(response) {
        $scope.franchiseGraphics = response.data;
        $rootScope.$broadcast("graphicsReady");
        hideLoader();
      });
    }

    $scope.getMenu = function() {
      showLoader();
      App.getMenu().then(function(response) {
        $scope.menu = {
          categories: response.data.categories,
          pizzas: response.data.pizzas
        };
        $scope.pizzaToppings = response.data.pizzaToppings;
        $rootScope.$broadcast("menuReady");
        hideLoader();
        var user = $scope.getItemFromLocalStorage("userDetails");
        if (user == undefined) {
          $location.path("login");
        } else {
          $location.path("menu");
        }
      });
    }

    $scope.getFranchise = function() {
      App.getFranchise().then(function(response) {
        $scope.franchise = response.data.franchise;
        $scope.branches = response.data.branches;
      });
    }

    $scope.getCombos = function() {
      App.getCombos().then(function(response) {
        $scope.combos = response.data;
      });
    }

    $scope.back = function() {
      window.history.back();
    }

    $scope.goTo = function(location) {
      $location.path(location);
    }

    $scope.hideDialog = function() {
      $mdDialog.hide();
    }

    $scope.slideUp = function() {
      $("html, body").animate({
        scrollTop: 0
      }, "fast");
    }



    $scope.setItemInLocalStorage = function(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    }

    $scope.getItemFromLocalStorage = function(key) {
      var value = localStorage.getItem(key);
      if (value == undefined) return value;
      else {
        return JSON.parse(value);
      }
    }

    $scope.verifyToken = function() {
      var token = $scope.getItemFromLocalStorage("loginToken");
      if (token == undefined) $scope.goTo("login");
      else {
        App.verifyToken(token).then(function(response) {
          if (!response.data) {
            localStorage.clear();
            $scope.goTo("login");
          }
        })
      }
    }

    $scope.getTotalOrderPrice = function() {
      return MyOrder.getTotalOrderPrice();
    }

    // Events
    $rootScope.$on("itemAddedToOrder", function(e, itemsCount) {
      $scope.itemsInOrderCount = itemsCount;
    });
    //On route change
    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
      $scope.currentLocation = toState.name;

      $state.current = $scope.rout;

      if (toState.data != undefined && toState.data.mainViewClasses != undefined) {
        $scope.mainViewClasses = toState.data.mainViewClasses;
      } else {
        $scope.mainViewClasses = "";
      }
      $scope.slideUp();

    });


    $scope.getMenu();
    $scope.getFranchiseGraphics();
    $scope.getFranchise();
    $scope.getCombos();

  }
}());
